import { Controller } from "@hotwired/stimulus"
import {
  hideV2,
  showV2,
  open,
  close
 } from "../utils/display"

export default class extends Controller {
  static targets = [
    'nameField',
    'template',
    'container',
    'error',
    'roomToEvaluate',
    'roomMeasurable',
    'actualRoomToEvaluate',
    'addButton',
    'roomToEvaluateAutodiag',
    'row',
    'modal',
    'modalOverlay',
    'deleteConfirmButton'
  ]

  connect() {
    if (this.hasRoomToEvaluateTarget) {this.roomToEvaluate = this.roomToEvaluateTarget}
    if (this.hasRoomToEvaluateAutodiagTarget) {this.roomToEvaluate = this.roomToEvaluateAutodiagTarget}
    const emptyNameFields = this.nameFieldTargets.filter(field => !field.hasAttribute('value'))
    if (emptyNameFields.length) {
      emptyNameFields.forEach(field => field.value = `Salle ${this.nameFieldTargets.indexOf(field) + 1}`)
    }
    if (this.hasErrorTarget) {this.fetchErrorMessage()}
    this.lastInputIndex = 0
    if (this.nameFieldTargets.length) {this.lastInputIndex = parseInt(/\d+/g.exec(this.nameFieldTargets[this.nameFieldTargets.length - 1].name)[0])}
  }

  add({currentTarget}) {
    if (this.addable(currentTarget)) {
      const newRoomRow = this.templateTarget.content.cloneNode(true).firstElementChild
      let newName = `Salle ${parseInt(this.actualRoomToEvaluateTarget.value) + 1}`
      if (this.nameFieldTargets.map((el) => el.value).includes(newName)) {
        newName = `Salle ${parseInt(this.actualRoomToEvaluateTarget.value) + 2}`
      }
      newRoomRow.querySelector('[data-reglementary-room-target="nameField"]').setAttribute('value', newName)
      const newRoom = newRoomRow.outerHTML.replace(/TEMPLATE_INDEX/g, this.lastInputIndex + 1)
      this.containerTarget.insertAdjacentHTML('beforeend', newRoom)
      this.addButtonTarget.parentNode.appendChild(this.addButtonTarget)
      this.actualRoomToEvaluateTarget.value = this.rowTargets.length
      this.lastInputIndex += 1
    }
    if (this.hasRoomToEvaluateAutodiagTarget) {this.roomToEvaluateAutodiagTarget.value = this.actualRoomToEvaluateTarget.value}
    this.toggleErrorMessage()
  }

  remove({currentTarget}) {
    const parentNode = currentTarget.parentNode
    parentNode.remove()
    if (!window.location.href.match(/edit_configuration$/g)) {
      this.nameFieldTargets.forEach((field, index) => field.value = field.value.replace(/Salle \d+$/g, `Salle ${index + 1}`))
    }
    this.actualRoomToEvaluateTarget.value = this.rowTargets.length
    
    if (this.hasRoomToEvaluateAutodiagTarget) {this.roomToEvaluateAutodiagTarget.value = this.actualRoomToEvaluateTarget.value}
    this.toggleErrorMessage()
  }

  toggleErrorMessage() {
    if (!this.hasErrorTarget) {return}
    if (this.errorCorrected()) {
      hideV2(this.errorTarget)
      document.querySelector('.room-measurable-container')?.classList.remove('error')
      document.querySelector('.room-to-evaluate-container')?.classList.remove('error')
    } else {
      showV2(this.errorTarget)
      this.fetchErrorMessage()
    }
  }

  fetchErrorMessage() {
    if (parseInt(this.roomToEvaluate.value) > parseInt(this.actualRoomToEvaluateTarget.value)) {
      const difference = parseInt(this.roomToEvaluate.value) - parseInt(this.actualRoomToEvaluateTarget.value)
      const dynamicText = this.dynamicText(difference)
      this.errorTarget.querySelector('p').innerHTML = `Vous avez renseigné un nombre de salles inférieur au minimum légal à évaluer. Merci d’ajouter ${dynamicText} ou de réajuster le nombre de pièces évaluables.`
      document.querySelector('.room-measurable-container')?.classList.add('error')
      document.querySelector('.room-to-evaluate-container').classList.add('error')
    } else if (this.hasRoomMeasurableTarget && (parseInt(this.roomMeasurableTarget.value) < parseInt(this.actualRoomToEvaluateTarget.value))) {
      const difference = parseInt(this.actualRoomToEvaluateTarget.value) - parseInt(this.roomMeasurableTarget.value)
      const dynamicText = this.dynamicText(difference)
      this.errorTarget.querySelector('p').innerHTML = `Le nombre de salles renseignées dépasse celui des salles évaluables. Merci de supprimer ${dynamicText} ou de réajuster le nombre de pièces évaluables.`
      document.querySelector('.room-measurable-container').classList.add('error')
    }
  }

  dynamicText(difference) {
    if (difference > 1) {
      return `${difference} salles`
    } else {return `${difference} salle`}
  }

  addable(currentTarget) {
    return currentTarget.type === 'button' ||
    ((!this.hasErrorTarget || this.errorTarget.classList.contains('is-hidden')) && 
    currentTarget.classList.contains('room_measurable') && 
    (parseInt(document.querySelector("input[name='form_establishment[min_nb_rooms_to_evaluate]']").value) > parseInt(this.actualRoomToEvaluateTarget.value)))
  }

  errorCorrected() {
    if (this.hasRoomMeasurableTarget) {
      return (parseInt(this.roomToEvaluate.value) <= parseInt(this.actualRoomToEvaluateTarget.value)) && (parseInt(this.roomMeasurableTarget.value) >= parseInt(this.actualRoomToEvaluateTarget.value))
    } else {
      return parseInt(this.roomToEvaluate.value) <= parseInt(this.actualRoomToEvaluateTarget.value)}
  }

  openModal({currentTarget}) {
    if (currentTarget.id) {
      open(this.modalTarget)
      open(this.modalOverlayTarget)
      this.deleteConfirmButtonTarget.dataset.roomToDelete = currentTarget.id
    } else {
      this.remove({currentTarget})
    }
  }

  closeModal ({currentTarget}) {
    close(this.modalTarget)
    close(this.modalOverlayTarget)
    const deleteTarget = document.getElementById(currentTarget.dataset.roomToDelete)
    this.remove({currentTarget: deleteTarget})
  }
}