import { Controller } from "@hotwired/stimulus"
import {showV2, hideV2, disabling, enabling, desactivate, activate} from "../utils/display"
import {th} from "date-fns/locale";

export default class extends Controller {
  static targets = ['step', 'roomToEvaluate', 'duplication', 'duplicationLabel', 'supervisorToggle', 'supervisorSelect']

  connect() {
    this.updateDuplicationLabel({detail: {field: 'kind', value: 'annual_evaluation'}})
    if(this.hasSupervisorSelectTarget) {
      this.updateSupervisorSelect({detail: {field: 'supervisor_id'}, target: this.supervisorSelectTarget})
    }
  }

  showStep(index) {
    this.stepTargets.forEach((step, i) => {
      if (i === index) {
        showV2(step);
      } else {
        hideV2(step);
      }
    });
  }

  nextStep() {
    const currentIndex = this.stepTargets.findIndex(step => !step.classList.contains("is-hidden"));
    if (currentIndex < this.stepTargets.length - 1) {
      this.showStep(currentIndex + 1);
    }
  }

  updateRoomToEvaluate({detail}) {
    if (detail.field === 'form_establishment[nb_rooms_measurable]') {
      let roomsToEvaluate = detail.value
      if (detail.value > 5) { roomsToEvaluate = Math.max(5, Math.round(detail.value / 2.0)) }
      if (detail.value > 39) { roomsToEvaluate = 20 }

      this.roomToEvaluateTarget.getElementsByTagName('input')[0].value = roomsToEvaluate
    }
  }

  updateDuplicationLabel({detail}) {
    if (detail.field ==='kind' && this.hasDuplicationTarget) {
      const { annualEvaluation, selfDiagnosis } = this.duplicationLabelTarget.dataset

      const radios = this.duplicationTarget.querySelectorAll('input')

      if (annualEvaluation && detail.value == 'annual_evaluation') {
        this.duplicationLabelTarget.textContent = this.duplicationLabelTarget.textContent.replace(/(?<=\« )(.*?)(?=\ »)/,`${annualEvaluation}`)
        showV2(this.duplicationTarget)
        radios.forEach((radio) => radio.disabled = false)
      } else if (selfDiagnosis && detail.value == 'self_diagnosis') {
        this.duplicationLabelTarget.textContent = this.duplicationLabelTarget.textContent.replace(/(?<=\« )(.*?)(?=\ »)/,`${selfDiagnosis}`)
        showV2(this.duplicationTarget)
        radios.forEach((radio) => radio.disabled = false)
      } else {
        hideV2(this.duplicationTarget)
        radios.forEach((radio) => radio.disabled = true)
      }
    }
  }

  updateSupervisorSelect({detail}) {
    if(detail.field === 'supervisor_id' && this.supervisorToggleTarget) {
      const canSubmitList = JSON.parse(this.supervisorSelectTarget.dataset.canSubmitList)


      if(canSubmitList[this.supervisorSelectTarget.selectedIndex - 1]) {
        this.supervisorToggleTarget.querySelector(".toggle-checkbox").checked = true
        this.supervisorToggleTarget.classList.add('is-desactivated')
      } else {
        this.supervisorToggleTarget.classList.remove('is-desactivated')
      }
    }
  }
}