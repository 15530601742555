import {Controller} from '@hotwired/stimulus'
import {show, hide, desactivate, disabling, enabling} from '../utils/display'

const selectAll = (selector) => Array.from(document.querySelectorAll(selector))

export default class extends Controller {
  static targets = [
    'sectionButton',
    'navStep',
    'sideNavContainer',
    'parentNavStep',
    'navSection',
    'loader',
    'successDialog'
  ]

  connect() {
    if (this.hasLoaderTarget) {
      setTimeout(() => {
        hide([this.loaderTarget])
        show([this.successDialogTarget])
      }, 2000);
    }
  }

  resetNav() {
    desactivate(this.navStepTargets)
    if (this.hasParentNavStepTarget) {
      desactivate(this.parentNavStepTargets)
    }
  }

  update({detail}) {
    const {sectionId, mainSection, step} = detail
    this.checkSection()
    this.resetNav()
    this.checkPhotoInput()

    this.navSectionTargets.forEach(navSection =>
      navSection.classList.toggle('is-hidden', navSection.dataset.section !== mainSection)
    )
    this.sectionButtonTargets.forEach(btn =>
      btn.classList.toggle('is-active', btn.dataset.section === mainSection)
    )
    this.parentNavStepTargets.forEach(parent =>
      parent.classList.toggle('is-active', parent.id == sectionId)
    )
    this.navStepTargets.forEach(navStep => {
      if (navStep.id === `${sectionId}-${step}`) {
        navStep.classList.add('is-active')
        this.scrollIntoViewIfNeeded(navStep)
      } else {
        navStep.classList.remove('is-active')
      }
    })
  }

  scrollIntoViewIfNeeded(target) {
    const boundingBox = this.sideNavContainerTarget.getBoundingClientRect()

    if (target.getBoundingClientRect().bottom > boundingBox.bottom) {
      target.scrollIntoView({behavior: 'smooth', block: 'end'})
    }

    if (target.getBoundingClientRect().top < boundingBox.top) {
      target.scrollIntoView({behavior: 'smooth', block: 'nearest'})
    }
  }

  checkSection() {
    const inputs = Array.from(document.querySelectorAll('[data-depends-on]'))
    const triggers = [...new Set(inputs.map(({dataset}) => dataset.dependsOn.split('-')[0]))]

    if (triggers.length) {
      triggers.forEach(trigger => {
        let input
        const triggerInputs = Array.from(document.querySelectorAll(`[data-event-from=${trigger}]`))

        if (triggerInputs.length > 1 ) {
          input = triggerInputs.find(i => {
            if (i.type === 'button') return document.querySelector(`option[value=${i.dataset.customSelectValueParam}]`)?.selected
            return i.checked
          })
          if (input) this.eventSwitch(input.dataset, input.value)
        } else if (triggerInputs.length === 1) {
          input = triggerInputs[0]
          if (input) this.eventSwitch(input.dataset, input.checked)
        }
      })
    }
  }

  checkPhotoInput(event) {
    const template = document.querySelector('#photoInputTemplate')
    if (template && this.noPhotoPresent(template.parentNode, event)) {
      this.addPhotoInput()
    }
  }

  noPhotoPresent(photoContainer, event) {
    const photoNbr = photoContainer?.querySelectorAll('img')?.length

    return !photoNbr || (event?.type == 'turbo:submit-end' && photoNbr == 1)
  }

  triggerEvent({currentTarget}) {
    const value = currentTarget.type === 'checkbox' ? currentTarget.checked : currentTarget.value
    this.eventSwitch(currentTarget.dataset, value)
  }

  eventSwitch({eventTriggered, eventFrom, index}, value) {
    const events = eventTriggered.split(' ')
    events.forEach(event => {
      switch (event) {
        case 'truthyToggle':
          selectAll(`[data-depends-on=${eventFrom}-disabling]`).forEach(element => {
            element.classList.toggle('is-disabled', value)
            const input = element.querySelector('input')

            if (input) {
              input.value = value ? (input.dataset.resetValue || null) : input.value
            }
          })
          break;
        case 'falsyToggle':
          selectAll(`[data-depends-on=${eventFrom}-enabling]`).forEach(element => {
            if (element.querySelector('input[type=checkbox]')) this.toggleToggle(element, value)
            element.classList.toggle('is-disabled', !value)
            if (value == false) this.reset(element)

            const input = element.querySelector('input')
            if (input && input.type != 'checkbox') {
              input.value = value ? input.value : (input.dataset.resetValue || null)
            }
          })
          selectAll(`[data-depends-on=${eventFrom}-resetting]`).forEach(element => {
            const input = element.querySelector('input')
            if (input.value == input.dataset.dynamicValue && !value) {
              input.value = null
            }
          })
          break;
        case 'autofillToggle':
          selectAll(`[data-autofilling-depends-on=${eventFrom}]`).forEach(element => {
            const input = element.querySelector('input')
            
            if (value) { input.value = input.dataset.dynamicValue || '' }
            else { input.value = input.dataset.initialValue || '' }
          })
          break;
        case 'disabled':
          const elements = selectAll(`[data-depends-on=${eventFrom}-enabling]`)
          elements.forEach(element => {
            const input = element.querySelector('input')

            if (input && input.type !== 'number') input.value = input.dataset.resetValue || null
          })
          disabling(elements)
          break;
        case 'enabled':
          enabling(selectAll(`[data-depends-on=${eventFrom}-enabling]`))
          break;
        case 'hidden':
          hide(selectAll(`[data-depends-on=${eventFrom}-displaying]`))
          break;
        case 'show':
          show(selectAll(`[data-depends-on=${eventFrom}-displaying]`))
          break;
        case 'tab':
          selectAll(`div.tab-panel[data-tab-for=${eventFrom}]`)
            .forEach(div => div.classList.toggle('is-active', div.id === `tab${index}`))
          this.resetEvaluationDate(value)
          if (value === 'csv_import') {this.resetDropzone()}
        case 'co2_measures':
          this.dispatch('updateCo2Measures')
          break
        default:
          break
      }
    })
  }

  resetEvaluationDate(value) {
    const el = document.querySelector('#evaluation_date')
    if (el) {
      el.dispatchEvent(new CustomEvent('reset', {detail: {value}}))
    }
  }

  resetDropzone() {
    const el = document.querySelector('#co2_import')
    if (el) {
      el.dispatchEvent(new CustomEvent('reset'))
    }
  }

  addPhotoInput() {
    const template = document.querySelector('#photoInputTemplate')
    const newPhotoInput = template.content.cloneNode(true).childNodes[0]
    const newId = `${new Date().valueOf()}_new`
    newPhotoInput.id = `form_value_photo_${newId}`
    newPhotoInput.innerHTML = newPhotoInput.innerHTML.replace(/photo_id/g, newId)
    template.parentNode.insertBefore(newPhotoInput, template.parentNode.lastChild)
  }

  reset(element) {
    switch (element.dataset.type) {
      case 'dropdown':
        this.resetDropdown(element)
        break;
      default:
        break;
    }
  }

  resetDropdown(element) {
    element.querySelector('select').value = ''
  }

  toggleToggle(element, value) {
    if (!value) {element.querySelector('input[type=checkbox]').checked = false}
    element.querySelector('input[type=checkbox]').toggleAttribute('disabled', !value)
  }
}
